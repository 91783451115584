import { setupTimeGetter } from './util/time';
import loadEnv from 'application.env';
import { setupLogger, LogLevel } from 'lib-common';

declare global {
  namespace ApplicationEnv {
    interface Env {
      API_GATEWAY_URL: string;
      SAP_CDC_CLIENT_ID: string;
      SAP_CDC_DOMAIN: string;
      SAP_CDC_LOGOUT: string;
      MANAGE_USERS_SAP_CDC: string;
      STUB_TIME?: string;
      DISABLE_AUTH?: string;
      LOG_LEVEL?: keyof LogLevel;
      GA4_TRACKING_ID: string;
      NODE_ENV?: string;
      UNDER_MAINTENANCE?: string;
      FEATURE_FLAGS_URL: string;
      FEATURE_FLAGS_CLIENT_KEY: string;
      REGION?: string;
    }
  }
}

export const configureEnvironment = (): Promise<ApplicationEnv.Env> =>
  loadEnv().then(async () => {
    if (window.env.NODE_ENV === 'development') {
      await loadEnv({ path: '/local.env', failSilently: true });
    }
    setupLogger({ level: window.env.LOG_LEVEL });
    setupTimeGetter();
    logger.debug({
      message: 'application.env',
      data: window.env,
    });
    return window.env;
  });
